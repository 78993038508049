import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const Hour = (props) => {    
    const { loginWithRedirect } = useAuth0();

    // const [isLoading, setIsLoading] = useState(false);

    const isHovering = props.selectedHour === props.name;

    const handleMouseOver = () => {
        props.setSelectedHour(props.name);
    };

    const handleMouseOut = () => {
        props.setSelectedHour("");
    };

    const handleClick = () => {
        // if (isLoading){
        //     return;
        // }
        // setIsLoading(true);
        if (props.isAuthenticated) {
            if (props.selected) {
                props.handleDeselectTime(props.name);
            } else {
                props.handleSelectTime(props.name, false);
            }
        } else {
            loginWithRedirect();
        }
    };

    // useEffect(() => {
    //     setIsLoading(false);
    // }, [props.selected])

    return (
        <div
            className={`origin-center relative hover:scale-[1.1] border-black border-[1px] flex flex-col justify-between xs:mx-3 mb-[0.3rem] xs:mb-2 sm:mb-3 h-[45px] sm:h-[60px] w-[140px] sm:w-[190px] items-center sm:p-[3px] cursor-pointer select-none ${props.selected ? 'bg-[#3562c4] text-white' : 'bg-transparent text-black'}`}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            onClick={handleClick}
        >
            <div className="text-xl sm:text-2xl font-ivymode flex">
                {props.formattedName.split(' ')[0]}
                <span className="text-[.5rem] sm:text-xs mt-[-4px] sm:mt-1 pl-1 flex items-start">{props.formattedName.split(' ')[1]}</span>
            </div>  
            <div className="mt-[-4px] sm:mt-0 proximanova text-[7px] sm:text-[10px] font-bold">
                {props.stat.quantity} {props.stat.quantity === 1 ? "PERSON":"PEOPLE"} PRAYING
            </div>
            {props.selected ? <div className="absolute top-0 right-0 pr-[6px]">x</div> : ''}
        </div>
    );
    // <tr>
    //     <td className="py-1.5">{props.name}</td>
    //     <td>{props.stat.quantity}</td>
    //     <td>
    //         <button onClick={handleClick}>
    //             {props.selected ? "Remove" : "Book"}
    //         </button>
    //     </td>
    // </tr>
    // <div>
    //     <button
    //         className="rounded-xl p-1 m-1 bg-slate-200 w-20"
    //         onMouseOver={handleMouseOver}
    //         onMouseOut={handleMouseOut}
    //         onClick={handleClick}
    //     >
    //         {isHovering
    //             ? props.selected
    //                 ? "Remove"
    //                 : "Book"
    //             : `${props.name} - ${props.stat.quantity}`}
    //     </button>
    // </div>
};
