import { startTransition, useState } from "react";
import { HourList } from "./HourList";

export const Day = (props) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const handleClick = () => {
        // setIsHovering(!isHovering);
        props.setSelected(props.active ? "" : props.name);
    };

    var count = 0;
    props.stats.forEach((stat) => {
        count += stat.quantity;
    });

    // const handleSelectTime = (time) => {
    //     props.incrementSlot(props.name, time);
    // };
    // const handleDeselectTime = (time) => {
    //     props.decrementSlot(props.name, time);
    // };

    return (
        <div className="flex-col w-[120px] sm:w-[150px] py-4 mx-[0.8rem] sm:mx-5 select-none">
            <div
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onClick={handleClick}
                className={`${
                    props.active || isHovering || props.isBooked
                        ? "border-[#333] text-white bg-[#3562c4]"
                        : "border-[#333] text-[#333] bg-white"
                } transition-colors ease-in-out duration-[400ms] pt-[100%] txt-center border-2 relative flex w-full justify-center items-center cursor-pointer`}
            >
                <div className="absolute top-[50%] translate-y-[-50%] flex-col flex justify-center items-center text-center font-ivymode">
                    <span className="text-lg">{props.name}</span>
                    <span className="text-4xl">{count}</span>
                    <span className="tracking-wider text-[0.5rem] leading-[0.75rem] font-proximanova">
                        {count === 1 ? "PERSON" : "PEOPLE"} PRAYING <br></br>ON THIS DAY
                    </span>
                </div>
            </div>
            {/* {props.active && (
                <div className="z-10 absolute">
                    <HourList
                        handleSelectTime={handleSelectTime}
                        handleDeselectTime={handleDeselectTime}
                        selectedTimes={props.selectedTimes}
                        stats={props.stats}
                    />
                </div>
            )} */}
        </div>
    );
};
