import { useState, useEffect, useRef } from "react";
// import TimezoneSelect from "react-timezone-select";
import "./App.css";
import { DayList } from "./components/DayList";
import { Profile } from "./components/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import { StatDisplay } from "./components/StatDisplay";
import { Footer } from "./components/Footer"
import { Link, Element, animateScroll as scroll } from "react-scroll";
import { createEvent } from "ics";
import { saveAs } from "file-saver";
// import AddToCalendar from "react-add-to-calendar-recurring";
import { atcb_action } from 'add-to-calendar-button'; 
// import createSubscriber from "pg-listen";
// import { config } from "./server/db";
import Cookies from 'js-cookie';

function App() {
    // State Management
    const [isLoadingInfo, setIsLoadingInfo] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [userCountry, setUserCountry] = useState("");
    const [userCity, setUserCity] = useState({id: 0, city: ""});
    const [newUserCountry, setNewUserCountry] = useState("");
    const [newUserCity, setNewUserCity] = useState({id: 0, city: ""});
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [newUserFirstName, setNewUserFirstName] = useState("");
    const [newUserLastName, setNewUserLastName] = useState("");
    const [selectedHour, setSelectedHour] = useState("");
    const [selectedDay, setSelectedDay] = useState("");
    const [prevSelectedDay, setPrevSelectedDay] = useState("");
    const [stats, setStats] = useState([]);
    const [unformattedStats, setUnformattedStats] = useState([]);
    const [selectionData, setSelectionData] = useState({
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
    });
    const [selectedTimezoneOffset, setSelectedTimezoneOffset] = useState(
        -new Date().getTimezoneOffset() / 60
    );

    const [DBGMetadata, setDBGMetadata] = useState(null);

    const [userMetadata, setUserMetadata] = useState({
        prayer_timezone_offset: null,
        selected_times: {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
        },
    });

    const [userInfo, setUserInfo] = useState(null); 

    const [authToken, setAuthToken] = useState(Cookies.get("auth-token"));

    const changeDay = (day) => {
        setPrevSelectedDay(selectedDay);
        setSelectedDay(day);
    };

    // End State Management

    // Prayer Stats (Postgres)

    useEffect(() => {
        getCountryList();
    }, [])

    useEffect(() => {
        getCities(newUserCountry);
    }, [newUserCountry])

    const getCountryList = async () => {
        const response = await fetch("https://prayapi.kingdomcity.com/countries");
        const jsonData = await response.json();
        setCountryList(jsonData);
    }

    const getCities = async (country) => {
        if (country === ""){
            return;
        }
        const response = await fetch(`https://prayapi.kingdomcity.com/cities/${country}`);
        const jsonData = await response.json();
        setCityList(jsonData);
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            getStatData();
        }, 60000);
        return () => clearInterval(intervalId);
    }, []);

    const getStatData = async () => {
        const response = await fetch("https://prayapi.kingdomcity.com/stats");
        const jsonData = await response.json();
        setUnformattedStats(jsonData);
        //formatStatDataForTimezone(jsonData);
    };

    const incrementSlot = async (unformattedDay, unformattedTime, isFull) => {
        loadPopup(true, unformattedDay, unformattedTime, isFull);
        if (isFull){
            return;
        }
        let { day, time } = formatDataforTimezoneReverse(
            unformattedDay,
            unformattedTime
        );
        let selectedTimesClone = userMetadata.selected_times;
        selectedTimesClone[day].push(time);
        // updateUserMetadata({ selected_times: selectedTimesClone });
        formatSelectionDataForTimezone(selectedTimesClone);
        var statsClone = [...unformattedStats];
        unformattedStats.forEach((stat, i) => {
            if (stat.day === day && stat.time === time){
                statsClone[i].quantity++;
            }
        });
        setUnformattedStats(statsClone);
        // setUserMetadata({
        //     ...userMetadata,
        //     selected_times: selectedTimesClone,
        // });
        const body = { day, time, increment: 1, auth0_id: userInfo.sub };
        try {
            await fetch("https://prayapi.kingdomcity.com/stats", {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
            });
        } catch (error) {
            console.error(error.message);
        }
        //getStatData();
    };

    const decrementSlot = (unformattedDay, unformattedTime) => {
        loadPopup(false, unformattedDay, unformattedTime, false);
    }

    const actualDecrementSlot = async (unformattedDay, unformattedTime) => {
        let { time, day } = formatDataforTimezoneReverse(
            unformattedDay,
            unformattedTime
        );
        let selectedTimesClone = userMetadata.selected_times;
        selectedTimesClone[day].splice(
            selectedTimesClone[day].indexOf(time),
            1
        );
        var statsClone = [...unformattedStats];
        unformattedStats.forEach((stat, i) => {
            if (stat.day === day && stat.time === time){
                statsClone[i].quantity--;
            }
        });
        setUnformattedStats(statsClone);
        formatSelectionDataForTimezone(selectedTimesClone); 
        // updateUserMetadata({ selected_times: selectedTimesClone });
        // setUserMetadata({
        //     ...userMetadata,
        //     selected_times: selectedTimesClone,
        // });
        const body = { day, time, increment: -1, auth0_id: userInfo.sub };
        try {
            await fetch("https://prayapi.kingdomcity.com/stats", {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
            });
        } catch (error) {
            console.error(error.message);
        }
        //getStatData();
    };

    // End Prayer Stats

    // Time Zone Selection
    const timeZoneOffsetList = [];
    for (let i = -11; i < 14; i++) {
        timeZoneOffsetList.push(i);
    }

    const handleTimezoneChange = (event) => {
        setSelectedTimezoneOffset(event.target.value);
        updateUserMetadata({ prayer_timezone_offset: event.target.value });
        // set user timezone somehow
    };

    // End Time Zone Selection

    // Time Zone Stat Modification
    const formatSelectionDataForTimezone = (data) => {
        let days = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
            "Monday",
        ];
        days[-1] = "Sunday";
        let selectionClone = {};
        let offset = parseInt(selectedTimezoneOffset);
        for (const day in data) {
            if (!selectionClone[day]) {
                selectionClone[day] = [];
            }
            data[day].forEach((time) => {
                let newDay = day;
                let hour = parseInt(time.slice(0, 2));
                if (hour + offset > 23) {
                    newDay = days[days.indexOf(newDay) + 1];
                } else if (hour + offset < 0) {
                    newDay = days[days.indexOf(newDay) - 1];
                }
                if (!selectionClone[newDay]) {
                    selectionClone[newDay] = [];
                }
                hour = (hour + offset + 24) % 24;
                selectionClone[newDay].push(
                    hour < 10 ? "0" + hour + ":00" : hour + ":00"
                );
            });
        }
        if (selectionClone) {
            setSelectionData(selectionClone);
        }
    };

    useEffect(() => {
        formatSelectionDataForTimezone(userMetadata.selected_times);
    }, [selectedTimezoneOffset, userMetadata.selected_times]);

    const formatStatDataForTimezone = (data) => {
        let days = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
            "Monday",
        ];
        days[-1] = "Sunday";
        let statsClone = data;
        let offset = parseInt(selectedTimezoneOffset);
        statsClone = statsClone.map((stat) => {
            let hour = parseInt(stat.time.slice(0, 2));
            let day = stat.day;
            if (hour + offset > 23) {
                day = days[days.indexOf(day) + 1];
            } else if (hour + offset < 0) {
                day = days[days.indexOf(day) - 1];
            }
            hour = (hour + offset + 24) % 24;
            return {
                time: hour < 10 ? "0" + hour + ":00:00" : hour + ":00:00",
                day: day,
                quantity: stat.quantity,
            };
        });
        if (statsClone.length != 0) {
            setStats(statsClone);
        }
        //setStats(statsClone);
    };

    useEffect(() => {
        formatStatDataForTimezone(unformattedStats);
    }, [selectedTimezoneOffset, unformattedStats]);

    const formatDataforTimezoneReverse = (day, time) => {
        let days = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
            "Monday",
        ];
        days[-1] = "Sunday";
        let offset = -parseInt(selectedTimezoneOffset);
        let hour = parseInt(time.slice(0, 2));
        if (hour + offset > 23) {
            day = days[days.indexOf(day) + 1];
        } else if (hour + offset < 0) {
            day = days[days.indexOf(day) - 1];
        }
        hour = (hour + offset + 24) % 24;
        return {
            time: hour < 10 ? "0" + hour + ":00:00" : hour + ":00:00",
            day: day,
        };
    };

    // End Time Zone Stat Modification

    // Auth 0 Integration
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } =
        useAuth0();

    const getUserInfo = async (authToken) => {
        try {
            setIsLoadingInfo(true);
            const response = await (
                await fetch('https://auth.kingdomcity.com/userinfo', {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    }
                })
            );
            const user_info = await response.json();
            setUserInfo(user_info);
            setIsLoadingInfo(false);
            return true;
        } catch(e) {
            console.log(e.message);
            setIsLoadingInfo(false);
            return false;
        }
    }

    const setupUserInfo = async () => {
        if (authToken){
            if (!(await getUserInfo(authToken))) {
                if (user) {
                    setUserInfo(user);
                }
            }
        } else if (user) {
            setUserInfo(user);
        } else {
            setIsLoadingInfo(false);
        }
    }

    useEffect(() => {
        setupUserInfo();
    }, [authToken, user])

    const saveUserDetails = async (firstName, lastName, country, city, isCreate) => {
        //await updateUserRootAttributes({given_name: firstName, family_name: lastName});
        if (isCreate){
            await createUserMetadata({first_name: firstName, last_name: lastName, city_id: city.id, email: userInfo.email.toLowerCase()});
        } else {
            await updateUserMetadata({first_name: firstName, last_name: lastName, city_id: city.id});
        }
        setUserFirstName(firstName);
        setUserLastName(lastName);
        setUserCity(city);
        setUserCountry(country);
    } 

    const createUserMetadata = async (data) => {
        try {
            await fetch("https://prayapi.kingdomcity.com/users", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({...data, auth0_id: userInfo.sub}),
            })
        } catch (e) {
            console.log(e.message);
        }
    }

    const updateUserMetadata = async (data) => {
        try {
            await fetch("https://prayapi.kingdomcity.com/users", {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({...data, auth0_id: userInfo.sub}),
            })
        } catch (e) {
            console.log(e.message);
        }
    };

    const getUserMetadata = async () => {
        if (userInfo) {
            try {
                // const accessToken = await getAccessTokenSilently({
                //     audience: `https://kingdomcity.au.auth0.com/api/v2/`,
                //     scope: "read:current_user",
                //     ignoreCache: true,
                // });
                // console.log(accessToken)
                const response = await (await fetch(`https://prayapi.kingdomcity.com/users?auth0_id=${userInfo.sub}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                })).json();
                setUserMetadata({...userMetadata, ...response});

                if (response) {
                    setNewUserFirstName(response.first_name ? response.first_name : userInfo.given_name);
                    setNewUserLastName(response.last_name ? response.last_name : userInfo.family_name);
                    setUserFirstName(response.first_name);
                    setUserLastName(response.last_name);
                    setUserCity(response.city);
                    setUserCountry(response.country);
                    setNewUserCity(response.city ? response.city : {id: 0, city: ""});
                    setNewUserCountry(response.country ? response.country : "");
                    if (response.prayer_timezone_offset) {
                        setSelectedTimezoneOffset(
                            response.prayer_timezone_offset
                        );
                    }
                } else {
                    if (userInfo.given_name){
                        setNewUserFirstName(userInfo.given_name);
                    }
                    if (userInfo.family_name){
                        setNewUserLastName(userInfo.family_name);
                    }
                }
                setDataLoaded(true);
            } catch (error) {
                console.error(error.message);
            }
        }
    };

    useEffect(() => {
        getStatData();
    }, []);

    useEffect(() => {
        if (isAuthenticated || userInfo) {
            getUserMetadata();
        }
    }, [getAccessTokenSilently, user?.sub, userInfo]);

    // End Auth 0 Integration

    // UI Animation
    // const [offset, setOffset] = useState(0);
    // const handleScroll = () => {
    //     setOffset(window.pageYOffset / 3);
    // };

    // useEffect(() => {
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // });

    const [hoverMenu, setHoverMenu] = useState(false);
    const handleMouseOver = () => {
        setHoverMenu(true);
    }

    const handleMouseOut = () => {
        setHoverMenu(false);
    }

    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [lastBookingTimestamp, setLastBookingTimestamp] = useState(0);
    const [timeSinceLastBooking, setTimeSinceLastBooking] = useState(0);

    const [isHovering, setIsHovering] = useState(false); // Controls "FIND OUT MORE" prayer meeting button
    const handleMouseOverPrayerMeeting = () => {
        setIsHovering(true);
    }
    const handleMouseOutPrayerMeeting = () => {
        setIsHovering(false);
    }
    const handleClickPrayerMeeting = () => {
        navigateTo('http://kingdomcity.com/pray/#meetings', true);
    }

    // END UI ANIMATION

    const setTimestamps = () => {
        const currentTimeStamp = Date.now();
        setTimeSinceLastBooking((currentTimeStamp - lastBookingTimestamp)/1000);
        setLastBookingTimestamp(currentTimeStamp);
    }

    const changeLoadingOverlay = (isLoading) => {
        setTimestamps();
        setLoadingOverlay(isLoading);
    }
    // End UI Animation

    // Misc UI
    const [calendarEvent, setCalendarEvent] = useState({title: "Kingdomcity 24/7 Prayer", startTime:"2022-09-10T20:15:00+08:00", recurring: { repeat: 'weekly' }});

    const handleSaveICS = () => {
        // const {day, time} = formatDataforTimezoneReverse(popupDetails.day, popupDetails.actualTime);
        const day = popupDetails.day;
        const time = popupDetails.actualTime;
        const dayArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayOffset = ((dayArray.indexOf(day) - (new Date()).getDay() + 7)%7);
        const timeZoneList = {
            "-11": "Pacific/Niue",
            "-10": "Pacific/Honolulu",
            "-9": "Pacific/Gambier",
            "-8": "Pacific/Pitcairn",
            "-7": "America/Phoenix",
            "-6": "America/Belize",
            "-5": "America/Cancun",
            "-4": "America/Puerto_Rico",
            "-3": "America/Argentina/Catamarca",
            "-2": "America/Noronha",
            "-1": "Atlantic/Cape_Verde",
            "0": "Etc/GMT",
            "1": "Africa/Lagos",
            "2": "Africa/Maputo",
            "3": "Africa/Nairobi",
            "4": "Asia/Dubai",
            "5": "Indian/Maldives",
            "6": "Asia/Urumqi",
            "7": "Asia/Bangkok",
            "8": "Asia/Singapore",
            "9": "Asia/Tokyo",
            "10": "Australia/Brisbane",
            "11": "Pacific/Guadalcanal",
            "12": "Pacific/Kwajalein",
            "13": "Pacific/Kanton"
        };
        atcb_action({
            name: "Kingdomcity 24/7 Prayer",
            startDate: `today+${dayOffset}`,
            startTime: (parseInt(time.split(":")[0]) < 10 ? '0' : '')+parseInt(time.split(":")[0])+":00",
            endTime: ((parseInt(time.split(":")[0])+1)%24 < 10 ? '0' : '') + (parseInt(time.split(":")[0])+1)%24 + ":00",
            recurrence: "RRULE:FREQ=WEEKLY",
            options:[
                "Apple",
                "Google",
                "iCal"
            ],
            // timeZone: 'Etc/GMT'+(selectedTimezoneOffset == 0 ? '' : ((-selectedTimezoneOffset > 0 ? '+' : '') + (-selectedTimezoneOffset))),
            timeZone: timeZoneList[selectedTimezoneOffset],
            iCalFileName: "247PrayerReminder"
        });
        return;
        //const dayArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        //const day = ((new Date()).getDate() + ((dayArray.indexOf(popupDetails.day) - (new Date()).getDay() + 7)%7))
        const month = day < (new Date()).getDate() ? (new Date()).getMonth()+2 : (new Date()).getMonth()+1;
        const event = { 
            start: [2022, month, day, parseInt(popupDetails.actualTime.split(":")[0]), 0],
            duration: {hours: 1},
            title: "Kingdomcity 24/7 Prayer",
            recurrenceRule:"FREQ=WEEKLY"
        }
        createEvent(event, (error, value) => {
            const blob = new Blob([value], {type:"text/plain;charset=utf-8"});
            saveAs(blob, "prayer-slot.ics");
        });
    }

    const navigateTo = (link, forceFlag=false) => {
        if (hoverMenu || forceFlag){
            window.location.href = link;
        }
    }

    const closePopupMenu = () => {
        setSelectedDay("");
        setPopup(false);
    }

    const loadPopup = (isTypeBooking, day="", time="", isFull) => {
        if (isFull) {
            // Catch Booking Cap
            setFull(true);
        } else if (isTypeBooking){
            setFull(false);
            let i = parseInt(time.split(":")[0]);
            let t = (i % 12 === 0 ? "12" : i % 12) +
                " - " +
                ((i % 12) + 1 === 0 ? "12" : (i % 12) + 1) +
                " " +
                (i < 11 || i === 23 ? "AM" : "PM")
            setPopupDetails({day: day, time: t, actualTime: time});
            setIsBooking(true);
        } else {
            setFull(false);
            setPopupDetails({day, time});
            setIsBooking(false);
        }
        setPopup(true);
    } 

    const handlePopupYes = () => {
        actualDecrementSlot(popupDetails.day, popupDetails.time);
        setPopup(false);
    }

    const handlePopupNo = () => {
        setPopup(false);
    }

    const [popupDetails, setPopupDetails] = useState({day: "", time: ""});
    const [isBooking, setIsBooking] = useState(true);
    const [full, setFull] = useState(false);
    const [popup, setPopup] = useState(false);
    // End Misc UI

    return (
        <div className="no-scrollbar App text-sm flex flex-col items-center w-full">
            <div className={`transition-all ease-in-out duration-[400ms] ${(dataLoaded && (!userFirstName || !userLastName)) ? 'fixed z-[17] w-full h-full top-0 left-0 bg-black/[0.6]' : 'opacity-0'}`}></div>
            <div className="fixed h-[54px] w-full shadow flex justify-center mb-5 z-20 bg-white">
                <div className="flex items-center max-w-[1080px] w-full h-full font-opensans">
                    <img src={process.env.PUBLIC_URL+'HeaderLogo.png'} className="mr-auto ml-3 h-[30px]"/>
                    {/* <h1 className="font-bold text-3xl ml-3 justify-self-start mr-auto">
                        {authToken}
                    </h1> */}
                    <div className="h-full justify-center items-center relative text-[#666] lg:flex hidden">
                        <div className="flex cursor-pointer items-center mr-8 hover:text-[#666]/[.6] transition-colors ease-in-out duration-[400ms]" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                            <a
                                className="mt-[20px] pb-[20px]"
                            >
                                ABOUT
                            </a>
                            <img src={process.env.PUBLIC_URL+"arrow_carrot-down.svg"} className="ml-1 invert-[.37] saturate-[6.37] brightness-[1.03] contrast-[0.86] hue-rotate-[169deg] w-[18px] pt-0.5"/>
                        </div>
                        <div
                            onMouseOver={() => {if(hoverMenu){ 
                                handleMouseOver()
                            }}}
                            onMouseOut={() => {if(hoverMenu){
                                handleMouseOut()
                            }}}
                            className={`shadow-[inset_0_3px_2px_-2px_rgba(0,0,0,0.1)] bg-white absolute py-4 left-[0px] top-[54px] w-[240px] z-10 origin-top ${
                                hoverMenu ? "opacity-100 scale-y-100 transition-all ease-in-out duration-[400ms]" : "opacity-0 scale-y-50"
                            }`}
                        >
                            <div onClick={() => {navigateTo('https://kingdomcity.com/about/')}} className={`select-none py-2 mx-4 px-4 hover:bg-black/[.03] transition-colors ease-in-out duration-[400ms] hover:text-[#666]/[.6] transition-colors ease-in-out duration-[400ms] ${hoverMenu ? 'cursor-pointer' : ''}`}>
                                KINGDOMCITY
                            </div>
                            <div onClick={() => {navigateTo('https://kingdomcity.com/jesus/')}} className={`select-none py-2 mx-4 px-4 hover:bg-black/[.03] transition-colors ease-in-out duration-[400ms] hover:text-[#666]/[.6] transition-colors ease-in-out duration-[400ms] ${hoverMenu ? 'cursor-pointer' : ''}`}>
                                JESUS
                            </div>
                        </div>
                    </div>
                    <Link
                        className="mx-2 mr-8 cursor-pointer lg:block hidden text-[#666] hover:text-[#666]/[.6] transition-colors ease-in-out duration-[400ms] lg:block hidden"
                        smooth={true}
                        duration={500}
                        to="prayerSchedule"
                    >
                        PRAYER SCHEDULE
                    </Link>
                    <Link
                        className="mx-2 cursor-pointer lg:block hidden text-[#666] hover:text-[#666]/[.6] transition-colors ease-in-out duration-[400ms] lg:block hidden"
                        smooth={true}
                        duration={500}
                        to="help"
                    >
                        HELP
                    </Link>
                    <div className="sm:ml-12 sm:text-sm text-xs">
                        <Profile
                            dataLoaded={dataLoaded}
                            newUserFirstName={newUserFirstName}
                            newUserLastName={newUserLastName}
                            setNewUserFirstName={setNewUserFirstName}
                            setNewUserLastName={setNewUserLastName}
                            user={userInfo}
                            isAuthenticated={userInfo}
                            isLoading={isLoadingInfo || isLoading}
                            userMetadata={userMetadata}
                            userFirstName={userFirstName}
                            userLastName={userLastName}
                            saveUserDetails={saveUserDetails}
                            cityList={cityList}
                            countryList={countryList}
                            newUserCity={newUserCity}
                            newUserCountry={newUserCountry}
                            setNewUserCity={setNewUserCity}
                            setNewUserCountry={setNewUserCountry}
                            userCountry={userCountry}
                            userCity={userCity}
                        />
                    </div>
                </div>
            </div>
            <div className="bg-black mt-[54px] w-full overflow-hidden relative flex justify-center min-h-[750px] shadow-[0_12px_30px_0px_#0a0905]">
                <video loop="loop" preload="none" muted playsInline autoPlay src='https://webcdn.kingdomcity.com/prayer/247_prayer.mp4' className="mx-auto w-full min-w-[1400px] object-cover"></video>
                <img src={process.env.PUBLIC_URL + "247-Prayer-Logo-980x187.png"} className="absolute m-auto top-0 left-0 right-0 bottom-0 w-[60%] max-w-[500px] max-h-[974px]" />
            </div>
            {/* <div
                style={{
                    backgroundImage: `url(/Prayer-Draft-Slide-Blank.jpg)`,
                    top: `${54 + offset}px`,
                }}
                className={`absolute left-0 bg-no-repeat bg-cover bg-top w-full h-[800.5px] -z-10`}
            ></div> */}
            {/* <div className="py-[22%] md:py-[15%] flex flex-col justify-end z-10 w-full">
                <img src={process.env.PUBLIC_URL + "247-Prayer-Logo.png"} className="translate-y-[40px] mx-auto w-[30%]" />
                <div
                    style={{
                        backgroundImage: "url(/247-Prayer-Logo.png)",
                    }}
                    className="h-full bg-no-repeat bg-center bg-[length:27%]"
                ></div>
            </div> */}
            <div style={{backgroundImage: "url(/Night-Sky-BG-v6.jpg)"}} className="bg-cover pb-16 bg-[#090704] w-full flex flex-col items-center">
                <div onMouseOver={() => {setSelectedHour("")}} className="w-full max-w-[1080px] flex flex-col px-3 items-center pt-24 pb-24 text-center">
                    <h2 className="font-ivymode text-white text-4xl pb-4">
                       “Pray without ceasing…” 
                    </h2>
                    <p className="font-ivymode text-xl text-white pb-10">
                        1 Thessalonians 5:17 NKJV
                    </p>
                    <p className="font-ivymode text-4xl text-white pb-10">
                        Join the prayer movement at Kingdomcity!
                    </p>
                    <div className="w-[300px] lg:w-auto flex flex-wrap items-stretch justify-center text-white">
                        <div className="min-w-[280px] pb-7 pt-3 px-6 mx-5 bg-white/[.29] flex-1">
                            <h2 className="font-ivymode text-4xl pb-3">1. LOG IN</h2>
                            <p className="font-opensans text-xl">CLICK THE BUTTON IN THE HEADER ABOVE TO LOG IN OR REGISTER</p>
                        </div>
                        <div className="min-w-[280px] pb-7 pt-3 px-6 mx-5 bg-white/[.29] flex-1">
                            <h2 className="font-ivymode text-4xl pb-3">2. BOOK</h2>
                            <p className="font-opensans text-xl">BOOK A TIME TO PRAY BY TAPPING YOUR PREFERRED DAY AND TIME BELOW</p>
                        </div>
                        <div className="min-w-[280px] pb-7 pt-3 px-6 mx-5 bg-white/[.29] flex-1">
                            <h2 className="font-ivymode text-4xl pb-3">3. ADD</h2>
                            <p className="font-opensans text-xl">AFTER BOOKING YOUR TIME, ADD IT TO YOUR CALENDAR</p>
                        </div>
                    </div>
                    <Element name="prayerSchedule" className="pt-24">
                    </Element>
                    <div className="flex mb-4">
                        <h2 className="font-opensans font-bold text-xl mr-2 text-white">Timezone:</h2>
                        <select
                            className="border-black border-[1.5px] px-6 py-1 outline-none"
                            onChange={handleTimezoneChange}
                            value={selectedTimezoneOffset}
                        >
                            {timeZoneOffsetList.map((item) => {
                                return (
                                    <option key={item} value={item}>
                                        GMT {item < 0 ? "" : "+"}
                                        {item}:00
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <DayList
                        // incrementSlot={incrementSlot}
                        // decrementSlot={decrementSlot}
                        // stats={stats}
                        selectedTimes={selectionData}
                        selectedDay={selectedDay}
                        setSelectedDay={changeDay}
                        stats={stats}
                    />
                    <p className="font-ivymode text-4xl text-white pb-5 pt-36">
                        Join a Prayer Meeting
                    </p>
                    <p className="font-opensans text-white">We have prayer meetings happening every day! Click the button below to join one today.</p>
                    <div className="pt-10 select-none">
                        <div
                            onMouseOver={handleMouseOverPrayerMeeting}
                            onMouseOut={handleMouseOutPrayerMeeting}
                            onClick={handleClickPrayerMeeting}
                            className={`${
                                isHovering
                                    ? "border-[#333] text-white bg-[#3562c4]"
                                    : "border-[#333] text-[#333] bg-white"
                            } transition-colors ease-in-out duration-[400ms] txt-center border-2 relative flex w-full justify-center items-center cursor-pointer py-4 px-10`}
                        >
                            FIND OUT MORE
                        </div>
                    </div>
                    <Element name="help">
                    </Element>
                </div>
            </div>
            <div
                className={`z-30 w-full sm:w-[90%] top-0 h-full fixed ${selectedDay ? 'visible opacity-100' : 'invisible opacity-0 z-[-1]'}`}
            >
                <div onClick={closePopupMenu} className={`top-0 left-0 bg-black/[0.6] w-full h-full fixed transition-all ease-in-out duration-[400ms] ${selectedDay ? 'visible opacity-100' : 'opacity-0 invisible'}`}></div>
                <div
                    className={`sm:h-auto overflow-y-auto z-30 h-full relative top-[50%] translate-y-[-50%] transition-all ease-out duration-700 w-full bg-white flex flex-col items-center ${selectedDay ? 'visible opacity-100' : 'invisible opacity-0'}`}
                >
                    <div className="max-w-[1080px] w-full flex flex-col items-center">
                        <div onClick={closePopupMenu} className="cursor-pointer font-white text-lg absolute right-0 top-0 pr-3 pt-3 pl-1 pb-1 z-10"><img className="w-[15px]" src={process.env.PUBLIC_URL + 'x.png'}/></div>
                        <StatDisplay
                            selectedTimes={selectionData}
                            selectedDay={selectedDay}
                            stats={stats}
                            incrementSlot={incrementSlot}
                            decrementSlot={decrementSlot}
                            prevSelectedDay={prevSelectedDay}
                            setSelectedHour={setSelectedHour}
                            selectedHour={selectedHour}
                            isAuthenticated={userInfo}
                            setLoadingOverlay={changeLoadingOverlay}
                            timeSinceLastBooking={timeSinceLastBooking}
                            loadingOverlay={loadingOverlay}
                        />
                    </div>
                    <div className={`${popup ? 'visible opacity-100' : 'invisible opacity-0 '} z-10 transition-all ease-in-out duration-[400ms] absolute top-0 left-0 w-full h-full bg-[#3562c4]/[.8] flex items-center justify-center`}>
                        {full ? <div>
                            <div className="w-[90%] p-4 bg-white flex flex-col items-center justify-center max-w-[500px] mx-auto">
                                <div className="text-center font-ivymode text-3xl mb-6">Thanks for your booking however this hour has now reached the maximum capacity of 40. Please select a different hour to pray.</div>
                                <div className="w-[80%] flex justify-center">
                                    <button onClick={handlePopupNo} className="font-proximanova w-[40%] transition-colors ease-in-out duration-[400ms] hover:bg-[#3562c4] mx-2 hover:text-white px-4 py-2 border-black border-[0.5px]">OK</button>
                                </div>
                            </div>
                        </div> : isBooking ? <div className="w-[90%] p-4 bg-white flex flex-col items-center justify-center max-w-[500px] relative">
                            <div onClick={handlePopupNo} className="cursor-pointer font-white text-lg absolute right-0 top-0 pr-3 pt-3 pl-1 pb-1 z-[11]"><img className="w-[10px]" src={process.env.PUBLIC_URL + 'x.png'}/></div>
                            <div className="text-center font-ivymode text-4xl mb-3">Your booking is confirmed!</div>
                            <div className="mb-3">{popupDetails.day}, {popupDetails.time}</div>
                            <div className="w-[80%] flex justify-center">
                                <button onClick={handleSaveICS} className="font-proximanova transition-colors ease-in-out duration-[400ms] hover:bg-[#3562c4] hover:text-white px-4 py-2 border-black border-[0.5px]">ADD TO YOUR CALENDAR</button>
                                {/* <AddToCalendar event={calendarEvent} /> */}
                            </div>
                        </div> : <div className="w-[90%] p-4 bg-white flex flex-col items-center justify-center max-w-[500px]">
                            <div className="text-center font-ivymode text-4xl mb-6">Are you sure you would like to cancel your booking?</div>
                            <div className="w-[80%] flex justify-center">
                                <button onClick={handlePopupYes} className="font-proximanova w-[40%] transition-colors ease-in-out duration-[400ms] hover:bg-[#3562c4] mx-2 hover:text-white px-4 py-2 border-black border-[0.5px]">YES</button>
                                <button onClick={handlePopupNo} className="font-proximanova w-[40%] transition-colors ease-in-out duration-[400ms] hover:bg-[#3562c4] mx-2 hover:text-white px-4 py-2 border-black border-[0.5px]">NO</button>
                            </div>
                        </div>}
                    </div>
                </div>
                {/* <div className={`top-0 h-full bg-black/[.4] overflow-hidden w-full absolute trainsition-all ease-out duration-700 text-white font-ivymode text-3xl flex items-center justify-center select-none md:h-[30rem] lg:h-[30rem] h-[40rem] ${loadingOverlay ? 'invisible opacity-100' : 'opacity-0 visible z-[-1]'}`}>Loading...</div> */}
            </div>
            {/* <footer onMouseOver={() => {setSelectedHour("")}} className="shadow-[-80px_12px_62px_54px_rgb(0,0,0)] py-20 px-[2rem] bg-black w-full text-white z-10 flex items-center justify-center">
                <div className="flex flex-wrap max-w-[1080px] w-full items-center justify-start sm:justify-center">
                    <div className="block pr-10 sm:justify-center">
                        <img className="w-[100px] min-w-[100px] -mt-16 sm:w-[222px]" src='https://webcdn.kingdomcity.com/prayer/phone_image.png'/>
                    </div>
                    <div className="pt-[1rem] sm:pt-20 sm:w-[300px] md:w-[440px] lg:w-[640px]">
                        <h2 className="font-ivymode text-4xl pb-4">Don't have our App yet?</h2>
                        <h2 className="font-ivymode text-4xl pb-5">Download it now!</h2>
                        <h3 className="font-opensans text-3xl pb-5">Available now on the App Store & Google Play!</h3>
                        <h3 className="font-opensans md:text-2xl text-xl pb-5">Releasing soon on the App Store & Google Play!</h3>
                        <div className="flex flex-wrap">
                            <img className="mr-4 w-[130px] sm:w-[174px] pb-7" src='https://webcdn.kingdomcity.com/prayer/AppStoreButton.png'/>
                            <img className="w-[140px] sm:w-[190px] pb-7" src=https://webcdn.kingdomcity.com/prayer/GooglePlayButton.png/>
                        </div>
                    </div>
                </div>
            </footer> */}
        </div>
    );
}

export default App;
