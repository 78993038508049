import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <button
            className="border-[#666] text-[#666] hover:border-[rgb(145,123,107)] hover:text-[rgb(145,123,107)] border-[1.5px] px-4 py-1.5 m-3 transition-colors ease-in-out duration-[400ms]"
            onClick={() => loginWithRedirect()}
        >
            LOG IN / REGISTER
        </button>
    );
};
