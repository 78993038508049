import { Hour } from "./Hour";

export const HourList = (props) => {
    const hours = [];
    const hoursFormatted = [];
    for (let i = 0; i < 24; i++) {
        hours.push(i < 10 ? "0" + i + ":00" : i + ":00");
        hoursFormatted.push(
            (i % 12 === 0 ? "12" : i % 12) +
                "-" +
                ((i % 12) + 1 === 0 ? "12" : (i % 12) + 1) +
                " " +
                (i < 11 || i === 23 ? "AM" : "PM")
        );
    }
    const hoursCol1 = hours.slice(0, 8);
    const hoursCol2 = hours.slice(8, 16);
    const hoursCol3 = hours.slice(16, 24);

    return (
        <div className="flex flex-row flex-wrap h-full max-h-[43rem] sm:max-h-full md:h-[40rem] lg:h-[30rem] sm:h-[60rem] py-2 xs:pt-2 pt-[0.3rem] sm:py-6 items-center justify-between xs:justify-center sm:max-w-[30rem] md:max-w-[50rem] lg:max-w-full">
            {hours.map((name, i) => {
                return (
                    <Hour
                        key={name}
                        name={name}
                        formattedName={hoursFormatted[i]}
                        handleSelectTime={props.handleSelectTime}
                        handleDeselectTime={props.handleDeselectTime}
                        selected={props.selectedTimes.includes(name)}
                        setSelectedHour={props.setSelectedHour}
                        selectedHour={props.selectedHour}
                        stat={
                            props.stats.filter(
                                (stat) => stat.time === name + ":00"
                            )[0]
                        }
                        isAuthenticated={props.isAuthenticated}
                    />
                );
            })}
        </div>
    );
};
