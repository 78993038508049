import { useEffect, useState } from "react";

export const Stats = () => {
    const [totalCount, setTotalCount] = useState(0);
    const [cityStats, setCityStats] = useState([]);
    const [signupStats, setSignupStats] = useState([]);
    const [campusStats, setCampusStats] = useState([]);

    const getStats = async () => {
        try {
            const [totalCountResponseJson, cityStatsResponseJson, signupStatsResponseJson, campusStatsResponseJson] = await Promise.all([
                fetch("https://prayapi.kingdomcity.com/userCount", {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                }),
                fetch("https://prayapi.kingdomcity.com/cityCount", {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                }),
                fetch("https://prayapi.kingdomcity.com/signupstats", {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }  
                }),
                fetch("https://prayapi.kingdomcity.com/campusstats", {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }  
                })
            ])
            const [totalCountResponse, cityStatsResponse, signupstatsResponse, campusStatsResponse] = await Promise.all([
                totalCountResponseJson.json(),
                cityStatsResponseJson.json(),
                signupStatsResponseJson.json(),
                campusStatsResponseJson.json()
            ])
            setTotalCount(totalCountResponse);
            setCityStats(cityStatsResponse);
            setSignupStats(signupstatsResponse);
            setCampusStats(campusStatsResponse);
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        getStats();
    }, []);
    return (
        <div>
            <h1 className="text-3xl my-4">Stats</h1>
            <h2 className="text-2xl my-4">Total User Count: {totalCount}</h2>
            <h2 className="text-2xl my-4">Breakdown by Campus</h2>
            <table className="my-4">
                <tr>
                    <th>Locations</th>
                    <th>Number of Users</th>
                </tr>
                {campusStats.map(stat => {
                    return (
                        <tr key={stat.city + ", " + stat.country}>
                            <td>{stat.city + ", " + stat.country}</td>
                            <td>{stat.user_count}</td>
                        </tr>
                    )
                })}
            </table>
            <h2 className="text-2xl my-4">Breakdown by City</h2>
            <table className="my-4">
                <tr>
                    <th>Locations</th>
                    <th>Number of Users</th>
                </tr>
                {cityStats.map(stat => {
                    return (
                        <tr key={stat.city + ", " + stat.country}>
                            <td>{stat.city + ", " + stat.country}</td>
                            <td>{stat.count}</td>
                        </tr>
                    )
                })}
            </table>
            <h2 className="text-2xl my-4">Breakdown by Praying Slots</h2>
            <table className="my-4">
                <tr>
                    <th>Praying Slots</th>
                    <th>Total Count</th>
                </tr>
                {signupStats.map(stat => { 
                    return (
                        <tr>
                            <td>{stat.slots}</td>
                            <td>{stat.count}</td>
                        </tr>
                    )
                })}
            </table>
        </div>
    );
}