import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from "./App";
import { Stats } from "./Stats";
import { ProtectedComponent } from "./components/ProtectedComponent";

export const RouterPage = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}/>
                <Route path="/reporting" element={<Stats />}/>
            </Routes>
        </BrowserRouter>
    )
}
