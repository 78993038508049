import { useState } from "react";
import { Day } from "./Day";

export const DayList = (props) => {
    const dayNames = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    const setSelected = (day) => {
        props.setSelectedDay(day);
    };

    // const incrementSlot = (day, time) => {
    //     props.incrementSlot(day, time);
    // };

    // const decrementSlot = (day, time) => {
    //     props.decrementSlot(day, time);
    // };

    return (
        <div className="flex w-full flex-wrap justify-center max-w-[900px]">
            {dayNames.map((name) => {
                return (
                    <Day
                        isBooked={props.selectedTimes[name].length != 0}
                        setSelected={setSelected}
                        name={name}
                        key={name}
                        active={name === props.selectedDay}
                        // incrementSlot={incrementSlot}
                        // decrementSlot={decrementSlot}
                        stats={props.stats.filter((stat) => stat.day === name)}
                        selectedTimes={props.selectedTimes[name]}
                    />
                );
            })}
        </div>
    );
};
