import { useState, useEffect } from "react";
import { HourList } from "./HourList";

export const StatDisplay = (props) => {
    const handleSelectTime = (time, isFull) => {
        props.setLoadingOverlay(true);
        props.incrementSlot(props.selectedDay, time, isFull);
    };
    const handleDeselectTime = (time) => {
        props.setLoadingOverlay(true);
        props.decrementSlot(props.selectedDay, time);
    };

    const setSelectedHour = (hour) => {
        props.setSelectedHour(hour);
    };

    const handleMouseLeave = () => {
        props.setSelectedHour("");
    };

    useEffect(() => {
        if (props.loadingOverlay){
            if (props.timeSinceLastBooking < 4){
                setTimeout(() => {props.setLoadingOverlay(false)}, 4000-props.timeSinceLastBooking*1000)
            } else {
                props.setLoadingOverlay(false);
            }
        }
    }, [props.selectedTimes])
    

    return (
        <div
            className="relative pt-3 w-full flex flex-col justify-center items-center px-4"
            onMouseLeave={handleMouseLeave}
        >
            <h1 className="font-ivymode mt-[-8px] sm:mt-0 text-3xl sm:text-4xl mb-1 sm:mb-4">{props.selectedDay}</h1>
            <p className="hidden sm:block font-proximanova">SELECT YOUR PREFERRED TIME BELOW</p>
            <p className="block text-xs sm:hidden font-proximanova text-center">TAP ON YOUR PREFERRED TIME<br></br>TO BOOK YOUR SLOT NOW!</p>
            {props.selectedDay ? (
                <>
                    <HourList
                        selectedHour={props.selectedHour}
                        setSelectedHour={setSelectedHour}
                        handleSelectTime={handleSelectTime}
                        handleDeselectTime={handleDeselectTime}
                        selectedTimes={props.selectedTimes[props.selectedDay]}
                        stats={props.stats.filter(
                            (stat) => stat.day === props.selectedDay
                        )}
                        isAuthenticated={props.isAuthenticated}
                    />
                </>
            ) : props.prevSelectedDay ? (
                <>
                    <HourList
                        handleSelectTime={handleSelectTime}
                        handleDeselectTime={handleDeselectTime}
                        selectedTimes={
                            props.selectedTimes[props.prevSelectedDay]
                        }
                        stats={props.stats.filter(
                            (stat) => stat.day === props.prevSelectedDay
                        )}
                        isAuthenticated={props.isAuthenticated}
                    />
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
