import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useState, useEffect } from "react";
import { LoginButton } from "./LoginButton";
import { LogoutButton } from "./LogoutButton";

export const Profile = (props) => {
    //const { user, isAuthenticated, isLoading } = useAuth0();
    const requiredSave = !props.userFirstName || !props.userLastName || !props.newUserCountry || !props.newUserCity;
    const [showDetails, setShowDetails] = useState(false);
    const [isUpdated, setIsUpdated] = useState(requiredSave);
    const [showRequired, setShowRequired] = useState(false);
    const [profileLoadingOverlay, setProfileLoadingOverlay] = useState(false);

    const toggleShowDetails = () => {
        if (!requiredSave){
            setShowDetails(!showDetails);
        }
    }
    
    const handleChangeFirstName = (e) => {
        props.setNewUserFirstName(e.target.value);
        if (requiredSave){
            setIsUpdated(e.target.value != "" && props.newUserLastName != "" && props.newUserCountry != "" && props.newUserCity.id != 0);
        } else {
            setIsUpdated(e.target.value != props.userFirstName && e.target.value != "" || props.newUserLastName != props.userLastName || props.userCountry != props.newUserCountry || props.userCity != props.newUserCity);
        }
    }
    
    const handleChangeLastName = (e) => {
        props.setNewUserLastName(e.target.value);
        if (requiredSave) {
            setIsUpdated(e.target.value != "" && props.newUserLastName != "" && props.newUserCountry != "" && props.newUserCity.id != 0);
        } else {
            setIsUpdated(e.target.value != props.userLastName && e.target.value != "" || props.newUserFirstName != props.userFirstName || props.userCountry != props.newUserCountry || props.userCity != props.newUserCity);
        }
    }
    
    const handleSave = () => {
        if (isUpdated){
            setProfileLoadingOverlay(true);
            props.saveUserDetails(props.newUserFirstName, props.newUserLastName, props.newUserCountry, props.newUserCity, requiredSave);
        }
    }

    const setCountry = (e) => {
        props.setNewUserCountry(e.target.value);
        props.setNewUserCity({id:0, name:""});
        setIsUpdated(false);
    }

    const setCity = (e) => {
        props.setNewUserCity({
            id: e.target.value, 
            city: props.cityList.filter((item) => {
                return item.id == e.target.value
            })[0].city});
        if (requiredSave) {
            setIsUpdated(e.target.value != 0 && props.newUserFirstName != "" && props.newUserLastName != "" && props.newUserCountry != "");
        } else {
           setIsUpdated(e.target.value != props.userCity.id || props.newUserFirstName != props.userFirstName || props.userCountry != props.newUserCountry || props.newUserLastName != props.userLastName); 
        }
    }

    useEffect(() => {
        if (profileLoadingOverlay){
            setProfileLoadingOverlay(false);
            setShowDetails(false);
        }
    }, [props.userFirstName, props.userLastName, props.userCountry, props.userCity]);

    useEffect(() => {
        if (props.dataLoaded){
            setShowRequired(requiredSave)
            setShowDetails(requiredSave);
            if (requiredSave){
                setIsUpdated(props.newUserFirstName != "" && props.newUserLastName != "" && props.newUserCountry != "" && props.newUserCity.id != 0)
            }
        }
    }, [props.dataLoaded])

    useEffect(() => {
        if (!requiredSave){
            setShowRequired(false);
            setIsUpdated(props.userFirstName != props.newUserFirstName || props.userLastName != props.newUserLastName || props.userCity != props.newUserCity || props.userCountry != props.newUserCountry)
        }
    }, [showDetails]);

    // useEffect(() => {
    //     console.log("isAuthenticated", props.isAuthenticated);
    // }, [props.isAuthenticated])
    
    
    if (props.isLoading) {
        return <div>Loading ...</div>;
    }
    
    return (
        <div className="flex items-center">
            {props.isAuthenticated ? (
                <>
                    <div className="relative">
                        <div className="flex items-center cursor-pointer text-[#666] hover:text-[#666]/[.6] transition-colors ease-in-out duration-[400ms]" onClick={toggleShowDetails}>
                            <img
                                className="rounded-full w-8 h-8 xs:mr-4"
                                src={props.user.picture}
                                alt={props.user.name}
                                referrerPolicy="no-referrer"
                            />
                            <h2 className="hidden sm:block xs:mr-4">{props.userFirstName && props.userLastName ? (props.userFirstName + " " + props.userLastName).toUpperCase() : props.user.name.toUpperCase()}</h2>
                        </div>
                        <div className={`shadow-[inset_0_3px_2px_-2px_rgba(0,0,0,0.1)] bg-white absolute py-4 -right-[110px] top-[43px] w-[280px] z-20 origin-top transition-all ease-in-out duration-[400ms] px-4 ${
                                    showDetails ? "opacity-100 scale-y-100 visible" : "opacity-0 scale-y-50 invisible"
                                }`}>
                                    <div className={`bg-black/[.7] h-full w-full absolute top-0 left-0 trainsition-all ease-out duration-700 text-white font-ivymode text-3xl flex items-center justify-center select-none ${profileLoadingOverlay ? 'z-[30] opacity-100' : 'z-[-1] opacity-0'}`}>Loading...</div>
                                    <h2 className="font-bold text-center text-lg">Details</h2>
                                    <p className={`text-[.7rem] text-[#ff0000] ${showRequired ? '' : 'hidden'}`}>*PLEASE REVIEW AND SAVE DETAILS</p>
                                    <p className="pb-1">First Name</p>
                                    <input className="w-full px-3 py-1 border-[#666] border-[1.5px] outline-none" value={props.newUserFirstName} onChange={handleChangeFirstName}/>
                                    <p className="pt-3 pb-1">Last Name</p>
                                    <input className="w-full px-3 py-1 border-[#666] border-[1.5px] outline-none" value={props.newUserLastName} onChange={handleChangeLastName}/>
                                    <p className="pt-3 pb-1">Country</p>
                                    <select className="w-full border-black border-[1.5px] px-3 py-1 outline-none overflow-hidden" onChange={setCountry} value={props.newUserCountry}>
                                        <option value="" disabled>Select a Country</option>
                                        {props.countryList.map((item) => {
                                            return (
                                                <option className="w-[200px] whitespace-pre-wrap text-ellipsis overflow-hidden" key={item.country} value={item.country}>
                                                    {item.country}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <p className="pt-3 pb-1">City</p>
                                    <select className="w-full border-black border-[1.5px] px-3 py-1 outline-none overflow-hidden" onChange={setCity} value={props.newUserCity.id}>
                                        <option value={0} disabled>Select a City</option>
                                        {props.cityList.map((item) => {
                                            return (
                                                <option className="w-[200px] whitespace-pre-wrap text-ellipsis overflow-hidden" key={item.id} value={item.id}>
                                                    {item.city}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div className="pt-3 flex justify-center w-full">
                                        <button className={`mx-auto px-2 py-1 text-[#666] hover:text-[#666]/[.6] transition-colors ease-in-out duration-[400ms] border-[1.5px] border-[#666] hover:border-[#666]/[.6] ${!isUpdated ? 'border-[#666]/[.6] cursor-not-allowed text-[#666]/[.6]' : ''}`} onClick={handleSave}>Save</button>
                                    </div>
                                </div>
                    </div>
                    <LogoutButton />
                    {/* <p>{props.user.email}</p>
                    <h3>User Metadata</h3>
                    {props.userMetadata ? (
                        <pre>
                            {JSON.stringify(props.userMetadata, null, 2)}
                        </pre>
                    ) : (
                        "No user metadata defined"
                    )} */}
                </>
            ) : (
                <LoginButton />
            )}
        </div>
    );
};
