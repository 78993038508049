import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterPage } from "./RouterPage";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";

const providerConfig = {
    domain: "auth.kingdomcity.com",
    clientId: "vxIrchGH4URpHFCk8ZbvdzzUIuho65Bz",
    audience: "https://kingdomcity.au.auth0.com/api/v2/",
    redirectUri: window.location.origin,
    //onRedirectCallback,
    scope: "read:current_user update:current_user_metadata",
    useRefreshTokens: true,
    cacheLocation: "localstorage"
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Auth0Provider
            {...providerConfig}
        >
            {/* <App /> */}
            <RouterPage />
        </Auth0Provider>
    </React.StrictMode>
);
